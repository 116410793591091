.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.preloader h4 {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 25px;
  margin-bottom: 20px;
  margin-left: 2px;
  margin-right: 2px;

}

.arabic_name {
  font-size: 47px;
  color: #F77A13;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 400px) {
  .arabic_name {
    font-size: 35px;
  }
}