.about-submenu {
    width: 12rem;
    position: absolute;
    text-align: center;
    top: 24px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F5F5F5;
}

.about-submenu li {
    background-color: #F5F5F5;
    cursor: pointer;
}

.about-submenu li:hover {
    color: #F77A13;
}

.about-submenu.clicked {
    display: none;
}

.submenu-item {
    background-color: #F5F5F5;
    display: block;
    width: 10rem;
    height: 100%;
    text-decoration: none;
    padding: 16px;
}