/* Hero Section CSS */

.hero_section {
    background-color: #e1f0f7;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../Images/hero_back.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;

}

.hero_section h1 {
    color: #f7f7f7;
    font-size: 80px;
    text-align: center;
    width: 70%;
}

.hero_section span {
    background-color: #F77A13;
}


.btnn {
    width: 180px;
    height: 42px;
    margin-top: 20px;
    background-color: #F77A13;
    border: 2px solid #FED420;
    border-radius: 8px;
    border-color: #F77A13;
    padding: 12px 30px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    font-size: 15px;
    color: white;

}

/* CEO Section CSS */

.ceo_back {
    background-color: #f2f2f2;
    background-image: url('../Images/ceo_back.png');
    background-size: cover;
    background-position: center;
    height: 720px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ceo_pic {
    background-color: white;
    background-image: url('../Images/ceo_img.jpg');
    background-size: cover;
    background-position: center;
    align-self: center;
    border-radius: 15px;
    height: 300px;
    width: 300px;
    order: 1;
}

.ceo_message {
    background-color: white;
    height: 80%;
    width: 70%;
    margin-left: 60px;
    align-self: center;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 2;
}

.ceo_message h1,
h2,
h4,
h5 {
    background-color: transparent;
    padding-left: 50px;
}

.ceo_message h1 {
    margin-top: 10px;
    font-size: 40px;
    font-family: "Anton", sans-serif;
    font-weight: 800;
    color: #F77A13;
    width: 90%;
}

.ceo_message h4 {
    font-size: 17px;
    font-weight: 400;
    padding-top: 20px;
    line-height: 1.5;
    text-align: justify;
    padding-right: 50px;
    margin-bottom: 20px;
}

.ceo_message h2 {
    padding-top: 20px;

}

.ceo_message h5 {
    padding-top: 10px;
    font-size: 17px;
    font-weight: 400;

}

#ceo_name {
    margin-bottom: 10px;
}

/* Every Section Title Text and Line CSS */
.section_text {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
}

.section_text h2 {
    padding-top: 10px;
    font-size: 45px;
    font-weight: 300;
    align-self: center;
    text-align: center;
    background-color: transparent;
}

.section_text span {
    color: #F77A13;
}

.line {
    margin: 0;
    margin-top: 10px;
    width: 100px;
    border-bottom: 2px solid #F77A13;
    align-self: center;

}

/* Clients Section CSS */
#clients_text {
    margin: 0;
    padding: 0;
    margin-top: 40px;
    margin-bottom: 30px;
    background-color: white;
}

#clients_text h2 {
    margin: 0;
    margin: 0;
    padding: 0;
}

#client_lines {
    margin: 0;
    align-self: center;
    margin-top: 10px;
}

.clients_images {

    width: 100%;
    display: flex;
    justify-content: space-evenly;
    overflow: none;

}

.clients_images img {
    height: 100px;
    width: 250px;
    margin-left: 15px;
    transition: all 0.5s ease;
}

.clients_images img:hover {
    transform: scale(1.1);
}

/* Services  CSS*/
#servicess {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 20px;

}

.services_text h2 {

    margin: 0;
    padding: 0;
}

#service_line {
    margin: 0;
    margin-top: 10px;
    align-self: center;
}

.services_item {
    margin-top: 10px;
    height: 350px;
    width: 270px;
    background-color: white;
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: transform 0.7s ease-in-out;
    cursor: pointer;
}

.services_item img {
    height: 80%;
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.services_item:hover #item_text h1 {
    color: white;
}

.services_item:hover #item_text {
    background-color: #F77A13;
}

#item_text {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

}

#item_text h1 {
    font-size: 22px;
    font-weight: 510;
    text-align: center;
    color: #3d424a;
    transition: transform 0.7s ease-in-out;

}

/* Responsiveness */

@media (max-width: 880px) {

    /* Hero Section Responsiveness */
    .hero_section h1 {
        font-size: 60px;
    }
}

@media (max-width: 450px) {
    .hero_section h1 {
        font-size: 50px;
    }
}



@media (max-width: 1166px) {


    /* CEO Section Responsivness */
    .ceo_back {
        height: 1000px;
        flex-direction: column;
        background-image: url('../Images/ceo_back_tab.png');

    }

    .ceo_message {

        border-radius: 15px;
        order: 1;
        width: 600px;
        margin: 20px;
        margin-left: 10px;
    }

    .ceo_pic {
        width: 250px;
        margin-top: 20px;
        margin-bottom: 20px;
        order: 2;
    }


}

@media (max-width: 900px) {

    /* Changing CEO Section Back Image When Tablet */
    .ceo_back {

        background-image: url('../Images/ceo_back_tab1.png');

    }
}

@media (max-width: 621px) {

    /* Changing CEO Section Back Image When Phone */
    .ceo_back {
        height: 900px;
        flex-direction: column;
        background-image: url('../Images/ceo_back_mobile.png');
        background-size: cover;
    }

    /* CEO Section Responsiveness when small devices */
    .ceo_message {
        margin-top: 20px;
        border-radius: 15px;
        order: 1;
        justify-content: flex-start;
        width: 90%;
        height: 500px;
        margin-left: 10px;
        margin-right: 10px;

    }

    .ceo_message h1 {
        margin-top: 10px;
    }

    .ceo_text {
        overflow: scroll;
        margin-bottom: 20px;
    }

    .ceo_pic {
        width: 250px;
        margin-top: 20px;
        margin-bottom: 20px;
        order: 2;

    }

    .text_div h1 {
        font-size: 35px;
    }

    .image_div img {
        width: 300px;
        height: 350px;
        margin-right: 20px;
    }
}