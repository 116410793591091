#services {
    height: 90vh;
    width: 100%;
    background-color: aqua;
}

/* Page Tiitle */
#services_title {
    height: 350px;
    margin-bottom: 0px;
}

#services_title h1,
#services_title p {
    text-align: center;
}

.service_main {
    margin-top: 10px;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

#services_section1 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#services_section2 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;


}

#services_line {
    margin-top: 15px;
    margin-left: 120px;
    width: 230px;
    align-self: self-start;
}

#services_section1 h1 {
    margin-left: 120px;
    font-size: 45px;
    font-weight: 300;
}

#services_section1 h3 {
    margin-left: 120px;
    font-size: x-large;
    font-weight: 540;
}

#services_section1 p {
    margin-top: 15px;
    margin-left: 120px;
    font-size: 17px;
    font-weight: 500;
}

#services_section2 img {
    height: 80%;
    width: 80%;
    padding: 0;
    margin: 0;
    margin-left: 30px;
}

@media (max-width: 1159px) {
    .service_main {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        height: auto;
    }

    #services_section1 {
        width: 100%;
        height: auto;
    }

    #services_section1 h1,
    #services_section1 p,
    #services_section1 h3 {
        margin-left: 5%;
        width: 90%;
        padding: 0;
    }

    #services_line {
        margin-left: 5%;
        padding: 0;
    }

    #services_section2 {
        margin-top: 20px;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #services_section2 img {
        margin: 0;
        height: 350px;
        width: 500px;
    }


}

@media (max-width: 573px) {
    #services_section2 img {

        height: 250px;
        width: 90%;
    }

    #services_line {
        width: 150px;
    }
}