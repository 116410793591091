@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

.circle-hover {
	position: relative;
	width: 70px;
	height: 70px;
	background-color: #f7f7f7;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	transition: all 0.4s ease;
	margin-top: 30px;
}

.circle-hover::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 200%;
	height: 200%;
	background-color: #F77A13;
	border-radius: 50%;
	transition: all 0.4s ease;
	transform: translate(-50%, -50%) scale(0);
	z-index: 0;
}

.project_card:hover .circle-hover::before {
	width: 100%;
	height: 100%;
	transform: translate(-50%, -50%) scale(1);
}

.hover_span {
	position: relative;
	font-size: 25px;
	z-index: 1;
	color: #F77A13;
	transition: color 0.4s ease;
}

.project_card:hover .hover_span {
	color: #ffffff;
}

.project_card {
	height: 300px;
	width: 300px;
	border-radius: 12px;
	overflow: hidden;
	box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	align-items: center;
}


.page_title {

	width: 100%;
	height: 250px;

	background-image: url('../Images/heading_img.jpg');
	background-size: cover;
	background-position: center;
	margin-bottom: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.background-overlay {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	transition: background 0.3s ease;

	display: flex;
	justify-content: center;
	align-items: center;
}

.page_title h1 {
	background-color: transparent;
	font-size: 45px;
	color: white;
}

.cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 25px;

}

.client {
	margin-top: 25px;
	justify-self: start;
	align-self: flex-start;
	margin-left: 5%;
	width: 95%;
	font-size: medium;
	text-align: start;
	justify-self: center;
	font-family: "Raleway", sans-serif;
	font-weight: 300;
}

.location {
	margin-top: 10px;
	justify-self: start;
	align-self: flex-start;
	padding-left: 5%;
	width: 95%;
	font-size: medium;
	text-align: start;
	justify-self: center;
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
}

.title {
	font-size: medium;
	width: 90%;
	margin-top: 25px;
	text-align: center;
	font-weight: 550;
	justify-self: center;
	font-family: 'Roboto Slab', serif;
}

.client_name,
.location_name {

	color: #F77A13;
}