.service_dropdown {
    background-color: #F5F5F5;
    width: 90rem;
    height: 90vh;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 20px;
    top: 140%;
    left: 50%;
    transform: translateX(-47%);
    transition: 0.5s;
    z-index: 1001;
    overflow-y: scroll;
}

#items {
    width: 220px;
    height: 280px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid gainsboro;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
}

#items.clicked {
    display: none;
}

#items img {
    height: 200px;
    width: 200px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

#items h1 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 300;
    text-align: center;
}

.service_dropdown.clicked {
    display: none;
}

a.dropdown-link {
    position: static;
}

a.dropdown-link::after {
    content: none;
}



.services_mobile_dropdown {
    background-color: #f5f5f5;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.services_mobile_dropdown h1 {
    font-size: large;
    font-weight: 300;
    line-height: 2.0;

}

.services_mobile {
    margin-top: 10px;
    margin-bottom: 10px;
    height: auto;
    width: auto;
    text-align: justify;
}

@media (max-width: 1447px) {
    .service_dropdown {
        width: 80rem;
        left: 58%;
        transform: translateX(-47%, );

    }
}

@media (max-width: 1312px) {
    .service_dropdown {
        left: 62%;
        width: 70rem;
        transform: translateX(-52%, );
    }
}

@media (max-width: 1175px) {
    .service_dropdown {
        width: 65rem;
        left: 68%;
        transform: translateX(-52%, );
    }

    #items {
        width: 200px;
        height: 250px;
        min-width: 150px;
    }

    #items img {
        height: 180px;
        width: 180px;
    }

}

@media (max-width: 1053px) {
    .service_dropdown {
        width: 55rem;
        left: 80%;
        transform: translateX(-52%, );
    }


}

@media (max-width: 891px) {
    .service_dropdown {
        width: 45rem;
        left: 80%;
        transform: translateX(-52%, );
    }

    #items {
        width: 180px;

    }

    #items img {
        height: 160px;
        width: 160px;
    }


}