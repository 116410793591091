/* Page Tiitle */

.page_title {
    width: 100%;
    height: 350px;
    background-image: url('../Images/heading_img.jpg');
    background-size: cover;
    background-position: center;
    margin-bottom: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    transition: background 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page_title h1 {
    background-color: transparent;
    font-size: 50px;
    color: white;
}

.page_title p {
    margin-top: 5px;
    color: white;
}

.contact_items {
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.carrds {
    height: 300px;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
}

.carrds img {
    padding-top: 32px;
    align-self: center;
    height: 90x;
    width: 90px;
}

.carrds h1 {
    font-family: 'Arial';
    font-size: x-large;
    padding-left: 20px;
    padding-top: 20px;
}

.p {
    padding-top: 20px;
    padding-left: 20px;
}

.ph2 {
    padding-top: 10px;
    padding-left: 20px;
}

.carrds span {
    color: #F77A13;
}

.carrds:hover {
    transform: scale(1.03);
    border: 1px solid #F77A13;
    background-color: white;
}

.email_div p {
    margin-right: 10px;
}

.address_div {
    margin-bottom: 10px;
}


/* contact Form  */
.contact {
    margin-top: 60px;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#contact_id{
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#contact_id h1{
    font-size: 45px;
    font-weight: 300;
    /* background-color: aqua; */

}


.line {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100px;
    border-bottom: 2px solid #F77A13;
    /* margin-left: 50px; */
    align-self: center;

}
#contact_id span{
    color: #F77A13;
}

.form_contact input,
.form_contact textarea {
    width: 100%;
    border: 2px solid #EFEFEF;
    outline: none;
    background-color: #EFEFEF;
    padding: 15px;
    margin-top: 15px;
    align-items: center;
    color: black;
    font-size: 15px;
    border-radius: 8px;

}

.email_phone_group {
    width: 650px;
    display: flex;
    justify-content: space-between;

}

.email_phone_group input {
    width: 47%;
    position: relative;

}

.column-contact {
    width: 95%;
}

.btn_submit {

    display: flex;
    border-radius: 20px;

    margin: 20px auto;
}

.btn_submit:hover {
    color: #F77A13;
}

.submitted_Text {
    font-size: 25px;
    margin-left: 10px;
    margin-right: 10px;
    align-self: center;
    justify-self: center;
}

@media (max-width: 1100px) {

    .contact_items {
        justify-content: space-evenly;
        align-items: center;
        height: 1100px;
        flex-direction: column;
    }

    .carrds {
        height: 300px;
        width: 50%;
    }
}

@media (max-width: 730px) {
    .contact {

        height: 530px;

    }

    .form_contact {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .form_contact input,
    .form_contact textarea {
        width: 85%;

    }

    .email_phone_group {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
}

@media (max-width: 550px) {
    .contact_items {
        justify-content: space-evenly;
        align-items: center;
        height: 1100px;
        flex-direction: column;
    }

    .carrds {
        height: 300px;
        width: 80%;
    }
}