* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: white;
  font-family: 'Poppins', sans-serif;
}

li,
a,
.btn1 {
  font-weight: 500;
  font-family: 16px;
  color: black;
  text-decoration: none;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
  height: 65px;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 5px;
  height: 65px;
}

.nav img {
  height: 35px;
  width: 90px;
}

.nav_links {
  list-style: none;
  height: 65px;
  align-self: center;
  cursor: pointer;
}

.nav_items {
  margin: 5px 20px;
  align-self: center;
  cursor: pointer;
  height: 100%;
  padding-top: 25px;
}

.contact_button1 {
  margin-top: 7px;
}

.nav_items a {
  color: black;
  position: relative;
}

.nav_items a::after {
  content: '';
  width: 0;
  height: 3px;
  background: #F77A13;
  position: absolute;
  left: 0;
  bottom: -6px;
  transition: 0.5s;
}

.nav_items a:hover::after {
  width: 100%;
}

.btn {
  padding: 9px 50px;
  background-color: transparent;
  border: 2px solid #F77A13;
  border-radius: 8px;
  border-color: #F77A13;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  font-size: 15px;
}

.btn_contact:hover {
  color: #F77A13;
}

.menu_icon {
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: black;
}

.nav_open {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  position: fixed;
  top: 55px;
  left: -10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  overflow-y: scroll;
  transition: 0.5s;
}

.nav_open li {
  line-height: 3.5;
  display:flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin: 10px 20px;
  align-self: center;
  cursor: pointer;
}

@media (min-width: 767px) {

  .nav_open {
    display: none;
  }

  .nav_links {
    display: flex;
  }

  .nav_items {
    display: inline-block;
  }
}

@media (max-width: 768px) {
  .nav {
    justify-content: space-between;
  }

  .nav_links {
    display: none;
  }


  .contact_button1 {
    display: none;
  }

  .menu_icon {
    display: block;
  }
}