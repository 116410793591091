.footer {
    height: 500px;
    width: 100%;
    display: flex;

    justify-content: space-evenly;
}

.footer_main {
    background-color: #eeeeee;
    width: 100%;
    height: auto;
    margin-top: 50px;
}

.company_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    background-color: transparent;
}

.company_info img {
    width: 170px;
    height: 70px;
    margin-bottom: 50px;
    background-color: transparent;
    margin-top: 100px;
}

.company_info h5 {
    font-size: medium;
    font-weight: 500;
    text-align: justify;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: transparent;
}

/* Section1  */
.section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 17%;
    background-color: transparent;

}

.section1 h1 {
    color: #F77A13;
    font-size: large;
    text-align: flex-start;
    background-color: transparent;

    margin-bottom: 18px;
}

.services_heading1 {
    margin-top: 58px;
}

.footer_items_div {
    width: auto;
    height: auto;
}

.footer_items {

    width: 100%;
    font-size: medium;
    font-weight: 500;
    line-height: 2.0;
    cursor: pointer;
    text-align: start;
}

.footer_items:hover {
    color: #F77A13;
    cursor: pointer;
}

/* Section2 */
.section2 {
    width: 17%;
}


/* Section3 */
.section3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 31%;
    background-color: transparent;
}

.section3 h1 {
    color: #F77A13;
    font-size: large;
    text-align: flex-start;
    background-color: transparent;

    margin-bottom: 18px;
}

.section3 h3 {
    line-height: 2.5;
    font-size: medium;
    font-weight: 500;
    background-color: transparent;
}

.locationn {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: transparent;
    margin-left: 0px;
}

.locationn img {
    margin-top: 7.5px;
    height: 20px;
    width: 20px;
    margin: 10px;
    background-color: transparent;
}

.copyright {
    width: 100%;
    height: auto;
}

.copyright h6 {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: medium;
    font-weight: 500;
    text-align: center;
    width: 95%;
    margin: auto;
}

@media (max-width: 970px) {
    .footer {
        height: auto;
        flex-direction: column;
    }

    .company_info {
        width: 80%;
        height: auto;
        justify-content: center;
        align-self: center;
    }

    .company_info h5 {
        margin: 0;
        padding-left: 10px;

    }

    .company_info img {
        margin-top: 20px;
        width: 220px;
        height: 100px;
    }

    .section1 {
        align-self: center;
        align-items: flex-start;
        justify-content: flex-start;
        width: 80%;
        height: auto;
        margin-top: 50px;
    }

    .section1 h1,
    .section1 h3 {
        margin-top: 0;
        margin-left: 10px;
    }

    .section3 {
        align-self: center;
        justify-content: flex-start;
        width: 80%;
        height: auto;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 50px;
        margin-bottom: 20px;

    }

    .services_heading1 {
        padding-top: 0;
        margin-top: 0;
        margin-left: 10px;
    }

}